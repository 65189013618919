<template>
  <div class="data-attributes">
    <data-table
      :title="$t('attributes')"
      :data="activeAttributesList"
      :data-columns="tableColumns"
      :empty-text="$t('noAttributes')"
      :not-found-text="$t('noAttributesFound')"
      selection="single"
      @refresh="getCloudAttributes"
      @selection-change="handleSelectionChange"
    >
      <template slot="action-tools">
        <el-button
          size="small"
          type="primary"
          class="el-button_icon--prefix"
          @click="showComputedDialog = true"
        >
          <icon height="14" width="14" name="add-circle" />{{ $t('addComputedAttribute') }}
        </el-button>
      </template>
    </data-table>

    <data-attributes-computed
      :attribute="selectedAttribute"
      :visible="showComputedDialog"
      @close="showComputedDialog = false"
      @closed="selectedAttribute = null"
    />

    <edit-dialog
      :visible="showEditDialog"
      :title="$t('editAttribute.title')"
      :data="[
        {
          name: 'name',
          value: selectedAttribute && selectedAttribute.name,
          label: $t('editAttribute.name.label'),
          message: $t('editAttribute.name.required'),
        },
        {
          name: 'description',
          value: selectedAttribute && selectedAttribute.description,
          label: $t('description'),
          type: 'textarea',
        },
      ]"
      :error="submitError"
      @clearError="submitError = false"
      @close="showEditDialog = false"
      @closed="selectedAttribute = null"
      @submit="handleEditAttribute"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataAttributesComputed from './components/DataAttributesComputed'
import DataTable from '@/components/DataTable'
import EditDialog from '@/components/EditDialog'

export default {
  name: 'DataAttributes',

  components: {
    DataAttributesComputed,
    DataTable,
    EditDialog,
  },

  data() {
    return {
      showComputedDialog: false,
      showEditDialog: false,
      selectedAttribute: null,

      tableColumns: ['name', 'type', 'profiles', 'description'],

      submitError: false,
    }
  },

  computed: {
    ...mapGetters('data', ['activeAttributesList']),
  },

  methods: {
    ...mapActions('data', ['getCloudAttributes', 'editCloudAttributes']),
    handleSelectionChange(attribute) {
      if (attribute.type === 'computed') {
        this.showComputedDialog = true
      } else {
        this.showEditDialog = true
      }
      this.selectedAttribute = attribute
    },
    async handleEditAttribute({ name, description }) {
      const { error } = await this.editCloudAttributes([
        {
          id: this.selectedAttribute.id,
          name,
          description,
        },
      ])

      if (error) {
        this.submitError = true
      } else {
        this.showEditDialog = false
      }
    },
  },
}
</script>

<i18n>
{
  "en": {
    "attributes": "Attributes",
    "noAttributes": "This cloud doesn't contain any attributes yet",
    "noAttributesFound": "No matching attributes found",
    "addComputedAttribute": "Add computed attribute",
    "editAttribute": {
      "title": "Edit attribute",
      "name": {
        "label": "Name",
        "required": "Please enter attribute name"
      }
    }
  },
  "ru": {
    "attributes": "Атрибуты",
    "noAttributes": "В этом облаке пока нет атрибутов",
    "noAttributesFound": "Атрибуты, соответствующие вашему запросу, не найдены",
    "addComputedAttribute": "Добавить вычисляемый атрибут",
    "editAttribute": {
      "title": "Редактировать атрибут",
      "name": {
        "label": "Название",
        "required": "Пожалуйста, введите название атрибута"
      }
    }
  }
}
</i18n>

<style lang="scss">
.data-attributes {
  width: 100%;
  padding: $spacing-l;
}
</style>
