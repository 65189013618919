<template>
  <div class="data-marketplace">
    <data-table
      :title="$t('dataMarketplace')"
      :data="data[activeTab]"
      :data-columns="tableColumns[activeTab]"
      :loading="loading"
      :empty-text="$t(`noData.${activeTab}`)"
      :not-found-text="$t(`noDataFound.${activeTab}`)"
      :selection="activeTab === 'marketplace' ? 'single' : 'none'"
      @refresh="getActiveTabData"
      @selection-change="handleSelectionChange"
    >
      <template slot="action-tools">
        <el-radio-group v-model="activeTab" size="small" @change="handleTabChange">
          <el-radio-button v-for="tab in tabs" :key="tab" :label="tab">{{
            $t(`tabs.${tab}`)
          }}</el-radio-button>
        </el-radio-group>
      </template>
    </data-table>

    <data-marketplace-settings
      :visible="showSettings"
      :item="selectedMarketplaceItem || {}"
      @close="showSettings = false"
    />
  </div>
</template>

<script>
import DataMarketplaceSettings from './components/DataMarketplaceSettings'
import DataTable from '@/components/DataTable'

/* eslint-disable */
const data = [
  { id: '1', name: 'Gillete', supplier: '1DMC', costPerAttribute: '1,000 $', paymentType: 'once', totalCost: '17,000 $', updated: 1570020806933, status: 'completed', profiles: 45, attributes: 23, date: 1570017689052 },
  { id: '2', name: 'Sony', supplier: '1DMC', costPerAttribute: '1,000 $', paymentType: 'once', totalCost: '17,000 $', updated: 1570020806933, status: 'completed',  profiles: 78, attributes: 24, date: 1570017689052 },
  { id: '3', name: 'Johnson & Johnson', supplier: '1DMC', costPerAttribute: '16,000 $', paymentType: 'periodic', totalCost: '17,000 $', updated: 1570020806933, status: 'active',  profiles: 89, attributes: 23, date: 1570017689052 },
  { id: '4', name: 'IBM', supplier: '1DMC', costPerAttribute: '11,000 $', paymentType: 'periodic', totalCost: '17,000 $', updated: 1570020806933, status: 'stalled',  profiles: 34, attributes: 23, date: 1570017689052 },
  { id: '5', name: 'Ferrari', supplier: '1DMC', costPerAttribute: '17,000 $', paymentType: 'periodic', totalCost: '17,000 $', updated: 1570020806933, status: 'stalled',  profiles: 898, attributes: 20, date: 1570017689052 },
  { id: '6', name: 'Apple', supplier: 'Aidata', costPerAttribute: '14,000 $', paymentType: 'periodic', totalCost: '17,000 $', updated: 1570020806933, status: 'completed',  profiles: 55457, attributes: 23, date: 1570017689052 },
  { id: '7', name: 'Samsung', supplier: 'Aidata', costPerAttribute: '11,000 $', paymentType: 'periodic', totalCost: '17,000 $', updated: 1570020806933, status: 'completed',  profiles: 89898, attributes: 93, date: 1570017689052 },
  { id: '8', name: 'Steam', supplier: '1DMC', costPerAttribute: '15,000 $', paymentType: 'once', totalCost: '17,000 $', updated: 1570020806933, status: 'active',  profiles: 665, attributes: 23, date: 1570017689052 },
  { id: '9', name: 'Nintendo', supplier: '1DMC', costPerAttribute: '1,000 $', paymentType: 'once', totalCost: '17,000 $', updated: 1570020806933, status: 'active',  profiles: 42, attributes: 23, date: 1570017689052 },
  { id: '0', name: 'Busket Robins', supplier: '1DMC', costPerAttribute: '1,000 $', paymentType: 'once', totalCost: '17,000 $', updated: 1570020806933, status: 'active',  profiles: 3, attributes: 23, date: 1570017689052 },
]
/* eslint-enable */

export default {
  name: 'DataMarketplace',

  components: {
    DataMarketplaceSettings,
    DataTable,
  },

  data() {
    return {
      tabs: ['marketplace', 'tickets', 'payments'],
      tableColumns: {
        marketplace: ['name', 'supplier', 'profiles', 'attributes', 'costPerAttribute', 'date'],
        tickets: [
          'name',
          'supplier',
          'profiles',
          'attributes',
          'paymentType',
          'costPerAttribute',
          'totalCost',
          'date',
          'updated',
          'status',
        ],
        payments: ['name', 'supplier', 'totalCost', 'date'],
      },
      activeTab: 'marketplace',

      data: {
        marketplace: [],
        tickets: [],
        payments: [],
      },

      selectedMarketplaceItem: null,
      showSettings: false,

      loading: false,
    }
  },

  async beforeMount() {
    this.loading = true
    await this.getActiveTabData()
    this.loading = false
  },

  methods: {
    async getActiveTabData() {
      if (this.activeTab === 'marketplace') {
        await this.getMarketplace()
      } else if (this.activeTab === 'tickets') {
        await this.getTickets()
      } else if (this.activeTab === 'payments') {
        await this.getPayments()
      }
    },
    async getMarketplace() {
      data.forEach(item => {
        if (item.date) {
          item.date = new Date(item.date).toLocaleTimeString(this.$i18n.locale)
          item.updated = new Date(item.updated).toLocaleTimeString(this.$i18n.locale)
        }
      })
      this.$set(this.data, 'marketplace', data)
    },
    async getTickets() {
      this.$set(this.data, 'tickets', data)
    },
    async getPayments() {
      this.$set(this.data, 'payments', data)
    },
    async handleTabChange() {
      if (!this.data[this.activeTab].length) {
        this.loading = true
        await this.getActiveTabData()
        this.loading = false
      }
    },
    handleSelectionChange(event) {
      this.selectedMarketplaceItem = event
      this.showSettings = true
    },
  },
}
</script>

<i18n>
{
  "en": {
    "dataMarketplace": "Data marketplace",
    "noData": {
      "marketplace": "No marketplace data yet",
      "tickets": "No tickets yet",
      "payments": "No payments yet"
    },
    "noDataFound": {
      "marketplace": "No matching marketplace data found",
      "tickets": "No matching tickets found",
      "payments": "No matching payments found"
    },
    "tabs": {
      "marketplace": "Marketplace",
      "tickets": "Tickets",
      "payments": "Payments"
    },
    "openSettings": "Open purchase settings"
  },
  "ru": {
    "dataMarketplace": "Обогащение данных",
    "noData": {
      "marketplace": "Данные по закупкам пока отсутствуют",
      "tickets": "Запросов нет",
      "payments": "Выплат нет"
    },
    "noDataFound": {
      "marketplace": "Данные по закупкам, соответствующие вашему запросу, не найдены",
      "tickets": "Данные, соответствующие вашему запросу, не найдены",
      "payments": "Данные по выплатам, соответствующие вашему запросу, не найдены"
    },
    "tabs": {
      "marketplace": "Закупка",
      "tickets": "Запросы",
      "payments": "Выплаты"
    },
    "openSettings": "Открыть настройки закупки"
  }
}
</i18n>

<style lang="scss">
.data-marketplace {
  width: 100%;
  padding: $spacing-l;
  .el-tabs--border-card {
    box-shadow: rgba(67, 90, 111, 0.1) 0px 0px 1px, rgba(67, 90, 111, 0.2) 0px 2px 4px -2px;
    border: none;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 0;
  }
}
</style>
