<template>
  <el-dialog
    class="data-attributes-computed"
    :visible="visible"
    :title="attribute ? $t('editAttribute') : $t('newAttribute')"
    @close="$emit('close')"
    @closed="handleReset"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="100px"
      :show-message="true"
      size="small"
    >
      <el-form-item key="type" prop="type" :label="$t('type.label')">
        <info-select
          v-model="formData.type"
          :label="$t(`type.options.${formData.type}.label`)"
          :options="typeOptions"
        />
      </el-form-item>

      <template v-if="formData.type === 'eventCounter'">
        <el-form-item key="event" prop="event" :label="$t('event.label')">
          <info-select
            v-model="formData.event"
            :label="activeEvent.name"
            :placeholder="$t('event.selectEvent')"
            show-groups
            :options="eventOptions"
          />
        </el-form-item>
      </template>

      <template v-if="formData.type !== 'eventCounter'">
        <el-radio-group v-model="formData.attributeType" size="small" class="computed__attribute">
          <el-radio-button label="user">{{ $t('profileAttribute.label') }}</el-radio-button>
          <el-radio-button label="event">{{ $t('eventAttribute.label') }}</el-radio-button>
        </el-radio-group>

        <template v-if="formData.attributeType === 'event'">
          <el-form-item key="event" prop="event" :label="$t('event.label')">
            <info-select
              v-model="formData.event"
              :label="activeEvent.name"
              :placeholder="$t('event.selectEvent')"
              show-groups
              :options="eventOptions"
            />
          </el-form-item>
          <el-form-item
            key="eventAttribute"
            prop="eventAttribute"
            :label="$t('eventAttribute.label')"
          >
            <el-select v-model="formData.eventAttribute" :placeholder="$t('selectAttribute')">
              <el-option
                v-for="eventAttribute in activeEvent.attributes"
                :key="eventAttribute"
                :label="eventAttribute"
                :value="eventAttribute"
              />
            </el-select>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item
            key="profileAttribute"
            prop="profileAttribute"
            :label="$t('profileAttribute.label')"
          >
            <el-select v-model="formData.profileAttribute" :placeholder="$t('selectAttribute')">
              <el-option-group
                v-for="(ids, name) in activeAttributeGroups"
                :key="name"
                :label="$t(`data.attributeGroups.${name}`)"
              >
                <el-option
                  v-for="id in ids"
                  :key="id"
                  :value="id"
                  :label="activeAttributes[id].name"
                />
              </el-option-group>
            </el-select>
          </el-form-item>
        </template>
      </template>

      <template v-if="formData.type === 'operation'">
        <el-form-item key="operation" prop="operation" :label="$t('operation.label')">
          <el-select v-model="formData.operation">
            <el-option
              v-for="operation in operations"
              :key="operation"
              :label="$t(`operation.options.${operation}`)"
              :value="operation"
            />
          </el-select>
        </el-form-item>
      </template>

      <template v-if="formData.type === 'mostFrequent'">
        <el-form-item
          key="minimumFrequency"
          prop="minimumFrequency"
          :label="$t('minimumFrequency.label')"
        >
          <el-input-number v-model="formData.minimumFrequency" :min="2" />
        </el-form-item>
      </template>

      <el-form-item key="timeRange" prop="timeRange" class="computed__time">
        <div class="computed__slider">
          <span>{{ $t('timeRange.label') }}</span>
          <el-select v-model="formData.timeRange.type" @change="handleTimeRangeTypeChange">
            <el-option value="within" :label="$t('timeRange.types.within')" />
            <el-option value="between" :label="$t('timeRange.types.between')" />
          </el-select>
        </div>
        <div class="computed__slider">
          <span>{{ $t('timeRange.days') }}</span>
          <el-slider
            v-model="formData.timeRange.value"
            :range="formData.timeRange.type === 'between'"
            :max="30"
          />
        </div>
      </el-form-item>
      <el-form-item key="conditions" prop="conditions">
        <conditions
          v-model="formData.conditions"
          class="computed__conditions"
          :option-placeholder="$t('selectAttribute')"
        >
          <template slot="options">
            <el-option-group
              v-for="(ids, name) in activeAttributeGroups"
              :key="name"
              :label="$t(`data.attributeGroups.${name}`)"
            >
              <el-option
                v-for="id in ids"
                :key="id"
                :value="id"
                :label="activeAttributes[id].name"
              />
            </el-option-group>
          </template>
        </conditions>
      </el-form-item>
      <el-form-item key="name" prop="name" :label="$t('name.label')">
        <el-input v-model="formData.name" />
      </el-form-item>
      <el-form-item key="description" prop="description" :label="$t('description')">
        <el-input v-model="formData.description" type="textarea" />
      </el-form-item>
    </el-form>

    <dialog-footer
      slot="footer"
      :loading="submitLoading"
      :disabled="submitDisabled"
      :submit-label="$t('actions.submit')"
      @cancel="$emit('close')"
      @submit="handleSubmit"
    />
  </el-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import InfoSelect from '@/components/InfoSelect'
import Conditions from '@/components/Conditions'
import DialogFooter from '@/components/DialogFooter'

export default {
  name: 'DataAttributesComputed',

  components: {
    InfoSelect,
    Conditions,
    DialogFooter,
  },

  props: {
    visible: Boolean,
    attribute: Object,
  },

  data() {
    return {
      formData: {
        type: 'eventCounter',
        conditions: [],
        timeRange: {
          type: 'within',
          value: 2,
        },
        name: '',
        description: '',

        attributeType: 'user',
        event: '',
        eventAttribute: '',
        profileAttribute: '',
        operation: 'sum',
        minimumFrequency: 2,
      },
      formRules: {
        type: {
          required: true,
          message: this.$i18n.t('type.required'),
          trigger: 'blur',
        },
        event: {
          required: true,
          message: this.$i18n.t('event.required'),
          trigger: 'blur',
        },
        eventAttribute: {
          required: true,
          message: this.$i18n.t('eventAttribute.required'),
          trigger: 'blur',
        },
        profileAttribute: {
          required: true,
          message: this.$i18n.t('profileAttribute.required'),
          trigger: 'blur',
        },
        conditions: {
          required: true,
          validator: (rule, value, callback) => {
            if (value.length && value[value.length - 1]) {
              if (Object.values(value[value.length - 1]).includes('')) {
                callback(new Error(this.$i18n.t('conditionRequired')))
              }
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        timeRange: {
          required: true,
          message: this.$i18n.t('timeRange.required'),
          trigger: 'blur',
        },
        name: {
          required: true,
          message: this.$i18n.t('name.required'),
          trigger: 'blur',
        },
      },

      types: ['eventCounter', 'operation', 'mostFrequent', 'unique'],
      operations: ['sum', 'average', 'minimum', 'maximum'],

      submitDisabled: false,
      submitLoading: false,
    }
  },

  computed: {
    ...mapState('data', ['events', 'attributes', 'activeCloudId']),
    ...mapGetters('data', [
      'activeAttributesList',
      'activeAttributes',
      'activeAttributeGroups',
      'activeEventsList',
      'activeEvents',
    ]),
    typeOptions() {
      return this.types.map(name => ({
        name,
        label: this.$i18n.t(`type.options.${name}.label`),
        description: this.$i18n.t(`type.options.${name}.description`),
        // example: this.$i18n.t(`type.options.${name}.example`),
        link: {
          href: '#',
          label: this.$i18n.t('actions.readMore'),
        },
      }))
    },
    eventOptions() {
      const groups = []
      const groupEvents = {}
      this.activeEventsList.forEach(event => {
        if (!groups.includes(event.group)) {
          groups.push(event.group)
          groupEvents[event.group] = []
        }
        groupEvents[event.group].push({
          name: event.id,
          label: event.name,
          description: event.description,
          link: {
            href: '#',
            label: this.$i18n.t('actions.readMore'),
          },
        })
      })
      return groups.map(group => ({ name: group, options: groupEvents[group] }))
    },
    activeEvent() {
      return this.activeEvents[this.formData.event] || {}
    },
  },

  watch: {
    visible(val) {
      if (val && this.attribute) {
        this.formData = {
          type: this.attribute.computedType || 'eventCounter',
          conditions: this.attribute.conditions || [],
          timeRange: this.attribute.timeRange || {
            type: 'within',
            value: 2,
          },
          name: this.attribute.name,
          description: this.attribute.description || '',

          attributeType: this.attribute.attributeType,
          event: this.attribute.event || '',
          eventAttribute: this.attribute.eventAttribute || '',
          profileAttribute: this.attribute.profileAttribute || '',
          operation: this.attribute.operation || 'sum',
          minimumFrequency: this.attribute.minimumFrequency || 2,
        }
      }
    },
    formData: {
      handler() {
        this.submitDisabled = false
      },
      deep: true,
    },
  },

  methods: {
    handleReset() {
      this.formData = {
        type: 'eventCounter',
        conditions: [],
        timeRange: {
          type: 'within',
          value: 2,
        },
        name: '',
        description: '',

        attributeType: 'user',
        event: '',
        eventAttribute: '',
        profileAttribute: '',
        operation: 'sum',
        minimumFrequency: 2,
      }
      this.$emit('closed')
    },
    handleTimeRangeTypeChange(type) {
      this.formData.timeRange.value = type === 'between' ? [0, 2] : 2
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            // await
            this.submitLoading = false
          } catch (error) {
            this.submitLoading = false
            this.submitDisabled = true
            return false
          }
        } else {
          this.submitDisabled = true
          return false
        }
      })
    },
  },
}
</script>

<i18n>
{
  "en": {
    "newAttribute": "New computed attribute",
    "editAttribute": "Edit computed attribute",
    "type": {
      "label": "Type",
      "required": "Please select attribute type",
      "options": {
        "eventCounter": {
          "label": "Event counter",
          "description": "Count the occurrence of an event within a period of time"
        },
        "operation": {
          "label": "Operation",
          "description": "Perform a mathematical operation on a numeric profile or event attribute value"
        },
        "mostFrequent": {
          "label": "Most frequent",
          "description": "Get the most frequent profile or event attribute value"
        },
        "unique": {
          "label": "Unique",
          "description": "Get unique profile or event attribute value"
        }
      }
    },
    "event": {
      "label": "Event",
      "required": "Please select event",
      "selectEvent": "Select event"
    },
    "operation": {
      "label": "Operation type",
      "required": "Please select operation type to compute",
      "options": {
        "sum": "Sum",
        "average": "Average",
        "minimum": "Minimum",
        "maximum": "Maximum"
      }
    },
    "eventAttribute": {
      "label": "Event attribute",
      "required": "Please select event attribute"
    },
    "profileAttribute": {
      "label": "Profile attribute",
      "required": "Please select profile attribute"
    },
    "minimumFrequency": {
      "label": "Minimum frequency",
      "required": "Please set minimum frequency"
    },
    "conditionRequired": "Please enter condition",
    "selectAttribute": "Select attribute",
    "name": {
      "label": "Name",
      "required": "Please enter attribute name"
    },
    "timeRange": {
      "label": "Time range",
      "required": "Please select time range",
      "types": {
        "within": "within",
        "between": "in between"
      },
      "days": "Days"
    }
  },
  "ru": {
    "newAttribute": "Новый вычисляемый атрибут",
    "editAttribute": "Редактировать вычисляемый атрибут",
    "type": {
      "label": "Тип",
      "required": "Пожалуйста, выберите тип атрибута",
      "options": {
        "eventCounter": {
          "label": "Счетчик событий",
          "description": "Расчитывает количество возникновений события за промежуток времени"
        },
        "operation": {
          "label": "Операция",
          "description": "Выполняет математическую операцию над числовым атрибутом профиля или события"
        },
        "mostFrequent": {
          "label": "Наиболее частый",
          "description": "Получает самое распространенное значение атрибута профиля или события"
        },
        "unique": {
          "label": "Уникальный",
          "description": "Получает уникальное значение атрибута профиля или события"
        }
      }
    },
    "event": {
      "label": "Событие",
      "required": "Пожалуйста, выберите событие",
      "selectEvent": "Выберите событие"
    },
    "operation": {
      "label": "Тип операции",
      "required": "Пожалуйста, выберите тип операции для вычисления",
      "options": {
        "sum": "Сумма",
        "average": "Среднее",
        "minimum": "Минимум",
        "maximum": "Максимум"
      }
    },
    "eventAttribute": {
      "label": "Атрибут события",
      "required": "Пожалуйста, выберите атрибут события"
    },
    "profileAttribute": {
      "label": "Атрибут профиля",
      "required": "Пожалуйста, выберите атрибут профиля"
    },
    "minimumFrequency": {
      "label": "Минимальная частота",
      "required": "Пожалуйста, укажите минимальную частоту"
    },
    "conditionRequired": "Пожалуйста, введите условие",
    "selectAttribute": "Выберите атрибут",
    "name": {
      "label": "Название",
      "required": "Пожалуйста, введите название атрибута"
    },
    "timeRange": {
      "label": "Временной интервал",
      "required": "Пожалуйста, выберите временной интервал",
      "types": {
        "within": "в пределах",
        "between": "в промежутке"
      },
      "days": "Дней"
    }
  }
}
</i18n>

<style lang="scss">
.data-attributes-computed {
  .el-dialog {
    max-width: 532px;
  }
  .el-select-dropdown__item {
    height: auto;
  }
}
.computed__conditions {
  padding: $spacing-s;
  border: 1px solid #ebedf6;
  border-radius: 4px;
}
.computed__slider {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: $spacing-m;
  &:last-of-type {
    margin-right: 0;
    flex: 2;
  }
  & > span {
    margin-bottom: $spacing-s;
  }
}
.computed__time .el-form-item__content {
  display: flex;
}
.computed__attribute {
  margin-bottom: $spacing-m;
}
</style>
