<template>
  <div class="data-sources">
    <data-table
      ref="table"
      :title="$t('sources')"
      :data="tableData"
      :data-columns="tableColumns"
      :empty-text="$t('noSourcesAdded')"
      :not-found-text="$t('noSourcesFound')"
      selection="single"
      @refresh="getCloudSources"
      @selection-change="handleSelectionChange"
    >
      <template slot="action-tools">
        <info-select
          :label="$t('addSource')"
          is-action
          :options="sourceTypeOptions"
          @change="handleShowDialog"
        />
      </template>
    </data-table>

    <component
      :is="activeDialog"
      :visible="showDialog"
      :edit-source="selectedSource"
      :class="showDialog && 'data-sources__dialog--modal'"
      @submitted="handleDialogSubmitted"
      @close="showDialog = false"
      @closed="selectedSource = null"
      @changeDialog="activeDialog = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfoSelect from '@/components/InfoSelect'
import DataTable from '@/components/DataTable'
import DataSourcesDsp from './components/DataSourcesDsp'
import DataSourcesPixel from './components/DataSourcesPixel'
import DataSourcesPixelCode from './components/DataSourcesPixelCode'
import DataSourcesApi from './components/DataSourcesApi'

export default {
  name: 'DataSources',

  components: {
    dsp: DataSourcesDsp,
    pixel: DataSourcesPixel,
    pixelCode: DataSourcesPixelCode,
    api: DataSourcesApi,
    DataTable,
    InfoSelect,
  },

  data() {
    return {
      activeDialog: '',
      showDialog: false,
      selectedSource: null,

      sourceTypes: [
        { name: 'dsp', icon: 'server' },
        { name: 'pixel', icon: 'application' },
        { name: 'api', icon: 'cog' },
      ], // add 'mobile', 'custom', 'file'
      tableColumns: ['name', 'typeLabel', 'uploadFrom', 'status'],
    }
  },

  computed: {
    ...mapGetters('data', ['activeSourcesList']),
    tableData() {
      if (!this.activeSourcesList) {
        return []
      }
      return this.activeSourcesList.map(source => {
        source.typeLabel = this.$i18n.t(`sourceTypes.${source.type}.title`)
        return source
      })
    },
    sourceTypeOptions() {
      return this.sourceTypes.map(type => ({
        name: type.name,
        icon: type.icon,
        label: this.$i18n.t(`sourceTypes.${type.name}.title`),
        description: this.$i18n.t(`sourceTypes.${type.name}.description`),
      }))
    },
  },

  methods: {
    ...mapActions('data', ['getCloudSources', 'getClouds']),
    handleSelectionChange(source) {
      this.selectedSource = source
      this.activeDialog = source.type
      this.showDialog = true
    },
    async handleDialogSubmitted() {
      this.showDialog = false
      await this.getCloudSources()
    },
    handleShowDialog(source) {
      this.activeDialog = source.name
      this.showDialog = true
    },
    handleShowEditDialog() {
      this.isEdit = true
      this.showDialog = true
      this.activeDialog = this.selectedSources[0].type
    },
  },
}
</script>

<i18n src="./locales.json"></i18n>
<i18n>
{
  "en": {
    "sources": "Sources",
    "noSourcesAdded": "You have not added any data sources to this cloud yet",
    "noSourcesFound": "No matching data sources found",
    "statusesDescription": "<b>Pending</b> — data source is waiting for acrivation/post processing<br><b>Active</b> — data source is active; data is being collected<br><b>Working</b> — work with data source is in progress<br><b>Stalled</b> — an error occured while working with data source<br><b>Deactivated</b> — data source is inactive; data isn't being collected<br>"
  },
  "ru": {
    "sources": "Источники",
    "noSourcesAdded": "Вы пока не добавили источники данных в это облако",
    "noSourcesFound": "Источники данных, соответствующие вашему запросу, не найдены",
    "statusesDescription": "<b>Pending</b> — источник данных создан и ожидает какой-либо активации/постобработки<br><b>Active</b> — источник данных активен; ведется сбор данных<br><b>Working</b> — ведется работа с источником данных<br><b>Stalled</b> — во время работы с источником данных произошла ошибка<br><b>Deactivated</b> — источник данных отключен; сбор данных не ведется<br>"
  }
}
</i18n>

<style lang="scss">
.data-sources {
  width: 100%;
  padding: $spacing-l;
  .el-dialog {
    max-width: 532px;
  }
}
.data-sources__dialog--modal {
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in-out;
}
.data-sources .el-activeDialog {
  max-width: 532px;
}
</style>
