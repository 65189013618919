<template>
  <el-dialog
    :visible="visible"
    :title="$t('sourceTypes.pixelCode')"
    :modal="false"
    class="data-sources-code"
    @close="$emit('close')"
    @closed="$emit('closed')"
  >
    <div class="data-sources-code__description" v-html="$t('description')" />
    <div class="data-sources-code__code">
      <pre>
&lt;script&gt;
    window._txq = window._txq || [];
    var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = '//st.hybrid.ai/txsp.js';
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(s);
    _txq.push(['createPixel', '{{ editSource ? editSource.id : 'id' }}']);
    _txq.push(['track', 'PageView']);
&lt;/script&gt;</pre
      >
    </div>

    <dialog-footer
      slot="footer"
      :no-cancel="!editSource"
      :submit-label="$t('actions.done')"
      :cancel-label="$t('actions.back')"
      @cancel="$emit('changeDialog', 'pixel')"
      @submit="editSource ? $emit('close') : $emit('submitted')"
    />
  </el-dialog>
</template>

<script>
import DialogFooter from '@/components/DialogFooter'

export default {
  name: 'DataSourcesPixelCode',

  components: {
    DialogFooter,
  },

  props: {
    visible: Boolean,
    editSource: Object,
  },
}
</script>

<i18n src="../locales.json"></i18n>
<i18n>
{
  "en": {
    "description": "Your website should work with one of <a href='https://iabeurope.eu/' target='_blank'>CMPs</a> and be able to send user ad agreement to Hybrid Warehouse for correct SmartPixel work."
  },
  "ru": {
    "description": "Для корректной работы SmartPixel с пользователями внутри ЕС, ваш сайт должен работать с одной из <a href='https://iabeurope.eu/' target='_blank'>CMP</a> и иметь возможность отправлять в Hybrid Warehouse данные о согласии пользователя на показ рекламы."
  }
}
</i18n>

<style lang="scss">
.data-sources-code__description {
  font-size: $size-text-l;
  line-height: 1.5;
  margin-bottom: 18px;
}
.data-sources-code__code {
  padding: $spacing-m;
  overflow: auto;
  background-color: $color-layout-light-03;
  border-radius: 4px;
}
.data-sources-code__code pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
