<template>
  <el-dialog
    class="data-marketplace-settings"
    :visible="visible"
    :title="$t('purchaseSettings')"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      class="data-marketplace-settings__content"
      :model="formData"
      label-width="100px"
      label-position="top"
      :show-message="true"
      size="small"
    >
      <el-form-item key="paymentInfo" :label="$t('paymentInfo')">
        <b>{{ item.name }} - </b>
        <span>{{ item.supplier }}</span>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item key="paymentType" prop="paymentType" :label="$t('paymentType.label')">
        <el-select v-model="formData.paymentType">
          <icon slot="prefix" width="16" height="16" name="count" />
          <el-option value="once" :label="$t('paymentType.options.once')" />
          <el-option value="periodic" :label="$t('paymentType.options.periodic')" />
        </el-select>
      </el-form-item>
      <template v-if="formData.paymentType === 'periodic'">
        <el-form-item key="paymentPeriod" prop="paymentPeriod" :label="$t('paymentPeriod.label')">
          <el-select v-model="formData.paymentPeriod">
            <icon slot="prefix" width="16" height="16" name="date" />
            <el-option value="daily" :label="$t('paymentPeriod.options.daily')" />
            <el-option value="weekly" :label="$t('paymentPeriod.options.weekly')" />
            <el-option value="monthly" :label="$t('paymentPeriod.options.monthly')" />
          </el-select>
        </el-form-item>
        <el-form-item key="paymentBudget" prop="paymentBudget" :label="$t('paymentBudget')">
          <el-input-number v-model="formData.paymentBudget" controls-position="right" :min="0" />
        </el-form-item>
      </template>

      <conditions
        v-model="formData.conditions"
        :options="activeAttributesList"
        class="purchase__conditions"
      />

      <div class="data-marketplace-settings__total">
        <div class="data-marketplace-settings__total__item">
          <span>{{ $t('numberOfProfiles') }}</span>
          <span>{{ item.profiles && item.profiles.toLocaleString($i18n.locale) }}</span>
        </div>
        <div class="data-marketplace-settings__total__item">
          <span>{{ $t('numberOfAttributes') }}</span>
          <span>{{ item.attributes && item.attributes.toLocaleString($i18n.locale) }}</span>
        </div>
        <div class="data-marketplace-settings__total__item">
          <b>{{ $t('totalCost') }}</b>
          <b>{{ (item.attributes * item.profiles).toLocaleString($i18n.locale) }} ₽</b>
        </div>
      </div>
    </el-form>
    <dialog-footer
      slot="footer"
      :submit-label="$t('purchase')"
      :loading="submitLoading"
      :disabled="submitDisabled"
      @cancel="$emit('close')"
      @submit="handleSubmit"
    />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Conditions from '@/components/Conditions'
import DialogFooter from '@/components/DialogFooter'

export default {
  name: 'DataMarketplaceSettings',

  components: {
    Conditions,
    DialogFooter,
  },

  props: {
    item: Object,
    visible: Boolean,
  },

  data() {
    return {
      formData: {
        paymentType: 'once',
        paymentPeriod: 'monthly',
        paymentBudget: 0,
        conditions: [],
      },

      submitLoading: false,
      submitDisabled: false,
    }
  },

  computed: {
    ...mapGetters('data', ['activeAttributesList']),
  },

  watch: {
    item: {
      handler() {
        this.handleReset()
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    handleReset() {
      this.formData.paymentType = 'once'
      this.formData.paymentPeriod = 'monthly'
      this.formData.paymentBudget = 0
      this.formData.conditions = []
    },
    handleSubmit() {
      this.handleReset()
    },
  },
}
</script>

<i18n>
{
  "en": {
    "purchaseSettings": "Purchase settings",
    "purchase": "Purchase",
    "paymentInfo": "Purchase info",
    "paymentBudget": "Purchase budget",
    "paymentType": {
      "label": "Payment type",
      "options": {
        "once": "Once",
        "periodic": "Periodic"
      }
    },
    "paymentPeriod": {
      "label": "Period",
      "options": {
        "daily": "Daily",
        "weekly": "Weekly",
        "monthly": "Monthly"
      }
    },
    "numberOfProfiles": "Number of profiles",
    "numberOfAttributes": "Number of attributes",
    "totalCost": "Total cost"
  },
  "ru": {
    "purchaseSettings": "Настройки закупки",
    "purchase": "Купить",
    "paymentInfo": "Информация о закупке",
    "paymentBudget": "Бюджет на закупку",
    "paymentType": {
      "label": "Тип закупки",
      "options": {
        "once": "Единоразовая",
        "periodic": "Периодическая"
      }
    },
    "paymentPeriod": {
      "label": "Период",
      "options": {
        "daily": "Ежедневно",
        "weekly": "Еженедельно",
        "monthly": "Ежемесячно"
      }
    },
    "numberOfProfiles": "Количество профилей",
    "numberOfAttributes": "Количество атрибутов",
    "totalCost": "Сумма закупки"
  }
}
</i18n>

<style lang="scss">
.purchase__conditions {
  padding: $spacing-s;
  border: 1px solid #ebedf6;
  border-radius: 4px;
  margin-bottom: $spacing-m;
}
.data-marketplace-settings__total {
  padding: $spacing-s;
  border: 1px solid #ebedf6;
  border-radius: 4px;
  & > * {
    margin-bottom: $spacing-s;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}
.data-marketplace-settings__total__item {
  display: flex;
  justify-content: space-between;
}
</style>
