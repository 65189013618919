<template>
  <el-dialog
    :visible="visible"
    :title="editSource ? $t('editSource') : $t('sourceTypes.pixel.title')"
    :modal="false"
    class="data-sources-pixel"
    @close="$emit('close')"
    @closed="$emit('closed')"
  >
    <p v-if="!editSource" class="data-sources-pixel__description">
      {{ $t('description') }}
    </p>
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="100px"
      :show-message="true"
      size="small"
    >
      <el-form-item key="name" prop="name" :label="$t('name.label')">
        <el-input v-model="formData.name" />
      </el-form-item>
    </el-form>

    <el-button
      v-if="editSource"
      class="data-sources-pixel__btn-code el-button_icon--prefix"
      @click="$emit('changeDialog', 'pixelCode')"
    >
      <icon height="16" width="16" name="code" />{{ $t('showCode') }}
    </el-button>

    <dialog-footer
      slot="footer"
      :submit-label="editSource ? $t('actions.save') : $t('addSource')"
      :show-delete="!!editSource"
      :loading="submitLoading"
      :disabled="submitDisabled"
      @cancel="$emit('close')"
      @submit="handleSubmit"
      @delete="handleDelete"
    />
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DialogFooter from '@/components/DialogFooter'

export default {
  name: 'DataSourcesPixel',

  components: {
    DialogFooter,
  },

  props: {
    visible: Boolean,
    editSource: Object,
  },

  data() {
    return {
      formData: {
        name: '',
      },
      formRules: {
        name: {
          required: true,
          message: this.$i18n.t('name.required'),
          trigger: 'blur',
        },
      },

      submitLoading: false,
      submitDisabled: false,
    }
  },

  computed: {
    ...mapState('data', ['activeCloudId']),
  },

  watch: {
    formData: {
      handler(data) {
        if (this.editSource && data.name === this.editSource.name) {
          this.submitDisabled = true
        } else {
          this.submitDisabled = false
        }
      },
      deep: true,
    },
    visible: {
      handler(val) {
        if (val) {
          this.resetData()
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('data', ['createCloud']),
    resetData() {
      this.$refs.form && this.$refs.form.clearValidate()
      if (this.editSource) {
        this.formData.name = this.editSource.name
      } else {
        this.formData.name = ''
      }
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            if (this.editSource) {
              await this.updatePixel()
            } else {
              await this.createPixel()
            }
          } catch (err) {
            this.submitLoading = false
            this.submitDisabled = true
            return false
          }
        } else {
          this.submitLoading = false
          this.submitDisabled = true
          this.createCloud = false
          return false
        }
      })
    },
    handleDelete() {}, // добавить метод для удаления
    async createPixel() {
      await this.$axios.post(`/api/datacloud/${this.activeCloudId}/pixel`, {
        name: this.formData.name,
        cloudId: this.activeCloudId,
      })
      this.submitLoading = false
      this.$emit('changeDialog', 'pixelCode')
    },
    async updatePixel() {
      await this.$axios.put('/api/smartpixel', {
        name: this.formData.name,
        id: this.editSource.id,
      })
      this.submitLoading = false
      this.$emit('submitted')
    },
  },
}
</script>

<i18n src="../locales.json"></i18n>
<i18n>
{
  "en": {
    "description": "Smart Pixel should be added to all your website pages right after Yandex.Metrica and Google Analytics counters.",
    "showCode": "Show Code"
  },
  "ru": {
    "description": "Smart Pixel необходимо установить на все страницы вашего сайта сразу после счетчиков Яндекс.Метрики и Google Analytics.",
    "showCode": "Показать Код"
  }
}
</i18n>

<style lang="scss">
.data-sources-pixel__description {
  font-size: $size-text-m;
  line-height: 1.5;
  margin-bottom: 18px;
}
.data-sources-pixel__btn-code {
  margin-top: $spacing-m;
}
</style>
