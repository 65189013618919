var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-attributes"},[_c('data-table',{attrs:{"title":_vm.$t('attributes'),"data":_vm.activeAttributesList,"data-columns":_vm.tableColumns,"empty-text":_vm.$t('noAttributes'),"not-found-text":_vm.$t('noAttributesFound'),"selection":"single"},on:{"refresh":_vm.getCloudAttributes,"selection-change":_vm.handleSelectionChange}},[_c('template',{slot:"action-tools"},[_c('el-button',{staticClass:"el-button_icon--prefix",attrs:{"size":"small","type":"primary"},on:{"click":function($event){_vm.showComputedDialog = true}}},[_c('icon',{attrs:{"height":"14","width":"14","name":"add-circle"}}),_vm._v(_vm._s(_vm.$t('addComputedAttribute'))+" ")],1)],1)],2),_c('data-attributes-computed',{attrs:{"attribute":_vm.selectedAttribute,"visible":_vm.showComputedDialog},on:{"close":function($event){_vm.showComputedDialog = false},"closed":function($event){_vm.selectedAttribute = null}}}),_c('edit-dialog',{attrs:{"visible":_vm.showEditDialog,"title":_vm.$t('editAttribute.title'),"data":[
      {
        name: 'name',
        value: _vm.selectedAttribute && _vm.selectedAttribute.name,
        label: _vm.$t('editAttribute.name.label'),
        message: _vm.$t('editAttribute.name.required'),
      },
      {
        name: 'description',
        value: _vm.selectedAttribute && _vm.selectedAttribute.description,
        label: _vm.$t('description'),
        type: 'textarea',
      },
    ],"error":_vm.submitError},on:{"clearError":function($event){_vm.submitError = false},"close":function($event){_vm.showEditDialog = false},"closed":function($event){_vm.selectedAttribute = null},"submit":_vm.handleEditAttribute}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }