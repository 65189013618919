<template>
  <el-dialog
    :visible="visible"
    :title="$t('sourceTypes.dsp.title')"
    :modal="false"
    class="data-sources-dsp"
    @close="$emit('close')"
    @closed="$emit('closed')"
  >
    <div v-if="loading" v-loading="loading" />
    <template v-else>
      <alert
        v-if="!activeDspAccount"
        class="data-sources-dsp__alert"
        :title="$t('accountNotFound.title')"
        :description="$t('accountNotFound.description')"
        inline
      />

      <div v-else>
        <el-form
          ref="form"
          :model="formData"
          :rules="formRules"
          label-position="top"
          label-width="100px"
          :show-message="true"
          class="data-sources-dsp__form"
          size="small"
        >
          <el-form-item
            key="dspAccount"
            class="data-sources-dsp__account"
            :label="$t('account.title')"
          >
            <el-select
              v-model="activeDspAccount"
              class="data-sources-dsp__form__select"
              @change="handleDspAccountChange"
            >
              <el-option
                v-for="account in dspAccounts"
                :key="account.name"
                :label="account.name"
                :value="account.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item key="dspDataCloud" prop="dspDataCloud" :label="$t('dspDataCloud.label')">
            <el-select
              v-model="formData.dspDataCloud"
              filterable
              :placeholder="$t('dspDataCloud.placeholder')"
              :no-match-text="$t('dataCloud.notFound')"
              :no-data-text="$t('dataCloud.empty')"
              class="data-sources-dsp__form__select"
              @change="handleDspDataCloudChange"
            >
              <el-option
                v-for="dataCloud in dspDataClouds[activeDspAccount]"
                :key="dataCloud.id"
                :label="dataCloud.name"
                :value="dataCloud.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item key="dspAudience" prop="dspAudience" :label="$t('dspAudience.label')">
            <el-select
              v-model="formData.dspAudience"
              filterable
              :placeholder="$t('dspAudience.placeholder')"
              :no-match-text="$t('dspAudience.notFound')"
              :no-data-text="$t('dspAudience.empty')"
              class="data-sources-dsp__form__select"
            >
              <el-option
                v-for="audience in dspAudiences[formData.dspDataCloud]"
                :key="audience.id"
                :label="audience.name"
                :value="audience.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item key="name" prop="name" :label="$t('name.label')">
            <el-input v-model="formData.name" />
          </el-form-item>
        </el-form>
      </div>

      <dialog-footer
        v-if="activeDspAccount"
        slot="footer"
        :submit-label="$t('addSource')"
        :loading="submitLoading"
        :disabled="submitDisabled"
        @cancel="$emit('close')"
        @submit="handleSubmit"
      />
    </template>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'

import Alert from '@/components/Alert'
import DialogFooter from '@/components/DialogFooter'

export default {
  name: 'DataSourcesDsp',

  components: {
    Alert,
    DialogFooter,
  },

  props: {
    visible: Boolean,
    editSource: Object,
  },

  data() {
    return {
      dspAccounts: [],
      activeDspAccount: '',
      dspDataClouds: {},
      dspAudiences: {},

      formData: {
        dspDataCloud: '',
        dspAudience: '',
        name: '',
      },
      formRules: {
        dspDataCloud: {
          required: true,
          message: this.$i18n.t('dspDataCloud.required'),
          trigger: 'blur',
        },
        dspAudience: {
          required: true,
          message: this.$i18n.t('dspAudience.required'),
          trigger: 'blur',
        },
        name: {
          required: true,
          message: this.$i18n.t('name.required'),
          trigger: 'blur',
        },
      },

      loading: false,
      submitLoading: false,
      submitDisabled: false,
    }
  },

  computed: {
    ...mapState('data', ['activeCloudId']),
  },

  watch: {
    formData: {
      handler() {
        this.submitDisabled = false
      },
      deep: true,
    },
    visible: {
      handler(val) {
        if (val) {
          this.resetData()
        }
      },
      immediate: true,
    },
  },

  async created() {
    this.loading = true
    await this.getAccounts()
    if (this.activeDspAccount) {
      await this.getDspDataClouds()
    }
    this.loading = false
  },

  methods: {
    resetData() {
      this.$refs.form && this.$refs.form.clearValidate()

      if (this.editSource) {
        this.formData.name = this.editSource.name
        this.formData.dspDataCloud = this.editSource.dspDataCloud
        this.formData.dspAudience = this.editSource.dspAudience
      } else {
        this.formData.name = ''
        this.formData.dspDataCloud = ''
        this.formData.dspAudience = ''
      }
    },
    async getAccounts() {
      try {
        this.dspAccounts = await this.$axios.get('/api/consoleinventory/accounts')
        const defaultAccount = await this.$axios.get('/api/consoleinventory/accounts/default')
        this.activeDspAccount = defaultAccount.id
      } catch (error) {
        if (error.status === 404 && this.dspAccounts.length) {
          this.activeDspAccount = this.dspAccounts[0].id
        }
        this.submitDisabled = true
      }
    },
    async getDspDataClouds() {
      try {
        const dataClouds = await this.$axios.get(
          `/api/consoleinventory/accounts/${this.activeDspAccount}/dataclouds`
        )
        this.$set(this.dspDataClouds, this.activeDspAccount, dataClouds)
      } catch (error) {
        this.submitDisabled = true
      }
    },
    async getDspAudiences() {
      try {
        const audiences = await this.$axios.get(
          `/api/consoleinventory/dataclouds/${this.formData.dspDataCloud}/audiences`
        )
        this.$set(this.dspAudiences, this.formData.dspDataCloud, audiences)
      } catch (error) {
        this.submitDisabled = true
      }
    },
    handleDspAccountChange() {
      if (!this.dspDataClouds[this.activeDspAccount]) {
        this.getDspDataClouds()
      }
    },
    handleDspDataCloudChange() {
      if (!this.dspAudiences[this.formData.dspDataCloud]) {
        this.getDspAudiences()
      }
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            await this.$axios.post(`/api/datacloud/${this.activeCloudId}/hybriddsp`, {
              name: this.formData.name,
              dataCloudId: this.activeCloudId,
              audienceId: this.formData.dspAudience,
            })
            this.submitLoading = false
            this.$emit('submitted')
          } catch (error) {
            this.submitLoading = false
            this.submitDisabled = true
            return false
          }
        } else {
          this.submitDisabled = true
          return false
        }
      })
    },
  },
}
</script>

<i18n src="../locales.json"></i18n>
<i18n>
{
  "en": {
    "dspCloud": {
      "label": "Hybrid DSP data cloud",
      "required": "Please select data cloud",
      "placeholder": "Select data cloud",
      "notFound": "Data cloud not found",
      "empty": "No data clouds"
    },
    "dspAudience": {
      "label": "Hybrid DSP audience",
      "required": "Please select audience",
      "placeholder": "Select audience",
      "notFound": "Audience not found",
      "empty": "No audiences"
    },
    "account": {
      "title": "Account",
      "useAnother": "Use Another Account",
      "choose": "Choose account",
      "noOther": "No other Hybrid Console accounts found"
    },
    "accountNotFound": {
      "title": "Hybrid Console account not found",
      "description": "Please create Hybrid Console account to import data from Hybrid DSP",
      "openConsole": "Open Hybrid Console"
    }
  },
  "ru": {
    "dspCloud": {
      "label": "Облако данных Hybrid DSP",
      "required": "Пожалуйста, выберите облако данных",
      "placeholder": "Выберите облако данных",
      "notFound": "Облако данных не найдено",
      "empty": "Нет облаков данных"
    },
    "dspAudience": {
      "label": "Аудитория Hybrid DSP",
      "required": "Пожалуйста, выберите аудиторию",
      "placeholder": "Выберите аудиторию",
      "notFound": "Аудитория не найдена",
      "empty": "Нет аудиторий"
    },
    "account": {
      "title": "Аккаунт",
      "useAnother": "Использовать другой аккаунт",
      "choose": "Выберите аккаунт",
      "noOther": "Другие аккаунты Hybrid Console не найдены"
    },
    "accountNotFound": {
      "title": "Не найден аккаунт Hybrid Console",
      "description": "Для импорта данных из Hybrid DSP необходимо завести аккаунт в Hybrid Console",
      "openConsole": "Открыть Hybrid Console"
    }
  }
}
</i18n>

<style lang="scss">
.data-sources-dsp__account__label {
  margin-bottom: $spacing-s;
  display: inline-block;
}
.data-sources-dsp__account {
  margin-bottom: $spacing-l;
}
.data-sources-dsp__account__container {
  display: flex;
  align-items: center;
}
.account-card {
  margin-right: $spacing-l;
}
.data-sources-dsp__form__select {
  width: 100%;
}
.account-card__container {
  margin-left: $spacing-s;
}
</style>
