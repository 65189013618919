import { render, staticRenderFns } from "./DataSourcesDsp.vue?vue&type=template&id=0fedd3c7&"
import script from "./DataSourcesDsp.vue?vue&type=script&lang=js&"
export * from "./DataSourcesDsp.vue?vue&type=script&lang=js&"
import style0 from "./DataSourcesDsp.vue?vue&type=style&index=0&id=0fedd3c7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=W%3A%5CAstraOne%5Crepo%5Cclient-warehouse%5Csrc%5Cviews%5Cdata%5Ccomponents%5CDataSourcesDsp.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./DataSourcesDsp.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports