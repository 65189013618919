<template>
  <el-dialog
    :visible="visible"
    :title="$t('sourceTypes.api.title')"
    :modal="false"
    class="data-sources-api"
    @close="$emit('close')"
    @closed="$emit('closed')"
  >
    <alert
      class="data-sources-api__alert"
      :title="$t('configuration.title')"
      :description="$t('configuration.description')"
      inline
      type="info"
    />

    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="100px"
      :show-message="true"
      size="small"
    >
      <el-form-item key="key" prop="key" :label="$t('apiKey.label')">
        <el-input v-model="formData.key">
          <icon slot="prefix" width="16" height="16" name="key" />
        </el-input>
      </el-form-item>
      <el-form-item key="name" prop="name" :label="$t('name.label')">
        <el-input v-model="formData.name" />
      </el-form-item>
    </el-form>

    <dialog-footer
      slot="footer"
      :submit-label="$t('addSource')"
      :loading="submitLoading"
      :disabled="submitDisabled"
      @cancel="$emit('close')"
      @submit="handleSubmit"
    />
  </el-dialog>
</template>

<script>
import Alert from '@/components/Alert'
import DialogFooter from '@/components/DialogFooter'

import { mapActions } from 'vuex'

export default {
  name: 'DataSourcesApi',

  components: {
    Alert,
    DialogFooter,
  },

  props: {
    visible: Boolean,
    editSource: Object,
  },

  data() {
    return {
      formData: {
        key: '',
        name: '',
      },
      formRules: {
        key: {
          required: true,
          message: this.$i18n.t('apiKey.required'),
          trigger: 'blur',
        },
        name: {
          required: true,
          message: this.$i18n.t('name.required'),
          trigger: 'blur',
        },
      },

      submitLoading: false,
      submitDisabled: false,
    }
  },

  watch: {
    formData: {
      handler() {
        this.submitDisabled = false
      },
      deep: true,
    },
    visible: {
      handler(val) {
        if (val) {
          this.resetData()
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('data', ['createCloud']),
    resetData() {
      this.$refs.form && this.$refs.form.clearValidate()
      this.formData.name = ''
      if (this.editSource) {
        this.formData.name = this.editSource.name
        this.formData.key = this.editSource.key
      }
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          // await добавить источник данных
          this.submitLoading = false
          this.$emit('submitted')
        } else {
          this.submitLoading = false
          this.submitDisabled = true
          return false
        }
      })
    },
  },
}
</script>

<i18n src="../locales.json"></i18n>
<i18n>
{
  "en": {
    "apiKey": {
      "label": "API key",
      "required": "Please enter key"
    },
    "configuration": {
      "title": "Data upload by API requires configuration",
      "description": "Follow our HTTP API guide for more details"
    }
  },
  "ru": {
    "apiKey": {
      "label": "API key",
      "required": "Пожалуйста, выберите ключ"
    },
    "configuration": {
      "title": "Для загрузки данных по API необходима конфигурация ресурса",
      "description": "Для получения полной информации посетите страницу руководства HTTP API"
    }
  }
}
</i18n>

<style lang="scss">
.data-sources-api__alert {
  margin-bottom: 18px;
}
</style>
